import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface TestimonialProps {
  name: string;
  position: string;
  photoUrl: string;
  description: string;
}
export const Testimonial: FC<TestimonialProps> = ({
  name,
  photoUrl,
  position,
  description,
}) => (
  <div className="l-10-cols-desktop l-island clearfix">
    <div className="l-8-cols-mobile l-island-mobile l-4-cols-tablet">
      <div className="quote-object l-push-bottom">
        <div className="quote-object-item color-border-primary-alt">
          <div className="image-object">
            <img alt={name} src={photoUrl} />
          </div>
        </div>
        <div className="quote-object-item">
          <div className="image-object align-center">
            <img alt="Quotation mark icon" src="/images/quote_primary.svg" />
          </div>
        </div>
      </div>
      <div className="l-push-bottom align-center-mobile">
        <div className="text-1-5 color-primary text-tight">{name}</div>
        <div className="color-faded text-upcase">{position}</div>
      </div>
    </div>
    <div
      className="l-8-cols-tablet l-dbl-push-bottom"
      style={{ textAlign: "left" }}
    >
      <ReactMarkdown allowDangerousHtml={true}>{description}</ReactMarkdown>
    </div>
  </div>
);
