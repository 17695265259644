import React, { FC } from "react";
import { Carousel } from "@/components/basic/Carousel";
import { Testimonial } from "@/components/basic/Testimonial";
import { graphql, useStaticQuery } from "gatsby";

export interface TestimonialCarouselProps {
  reversedTestimonialsOrder?: boolean;
}

export const TestimonialCarousel: FC<TestimonialCarouselProps> = ({
  reversedTestimonialsOrder = true,
}) => {
  const data: GatsbyTypes.Query = useStaticQuery(graphql`
    {
      allContentfulTestimonial {
        edges {
          node {
            portraitPhoto {
              file {
                url
              }
            }
            childContentfulTestimonialBodyTextNode {
              body
            }
            fullName
            position
          }
        }
      }
    }
  `);

  const testimonials = reversedTestimonialsOrder
    ? [...data.allContentfulTestimonial.edges].reverse()
    : [...data.allContentfulTestimonial.edges];

  return (
    <Carousel>
      {testimonials.map(({ node }) => (
        <Testimonial
          key={node.fullName}
          name={node.fullName}
          position={node.position}
          photoUrl={node.portraitPhoto.file.url}
          description={node.childContentfulTestimonialBodyTextNode.body}
        />
      ))}
    </Carousel>
  );
};
