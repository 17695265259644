import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { ProductCard } from "@/components/basic/Card";
import { TestimonialCarousel } from "@/components/TestimonialCarousel";
import { QualitiesList } from "@/components/QualitiesList";
import { TextSection } from "@/components/layout/TextSection";
import { Link } from "@/components/basic/Link";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import ReactMarkdown from "react-markdown";

const ProfessionalServicesPage: FC<PageProps<GatsbyTypes.Query>> = ({
  data,
}) => {
  const content = data.contentfulProfessionalServicesPage;
  return (
    <MainLayout pageName="professional-services">
      <Helmet>
        <body className="ltr professional-services" id="intro" />
      </Helmet>
      <SEO
        title={content.metaTitle}
        description={content.metaDescription.metaDescription}
        keywords={content.metaKeywords}
      />
      <section
        className="l-wrapper color-bg-negative"
        style={{ marginTop: 50 }}
      >
        <div className="clearfix l-dbl-push-bottom d-flex-box d-flex-box-wrap">
          {content.productPagesLeads.map(
            ({ shortName, lead: { lead }, icon: { file }, active, url }) => (
              <ProductCard
                title={shortName}
                description={lead}
                iconUrl={file.url}
                to={`/${url}`}
                key={shortName}
                active={active}
              />
            )
          )}
        </div>
      </section>
      <div className="testimonial-box l-dbl-push-bottom color-bg-light l-with-dbl-vertical-gutters-mobile">
        <div className="l-wrapper clearfix">
          <TestimonialCarousel />
        </div>
      </div>
      <QualitiesList markdown={content.qualitiesList.qualitiesList} />
      <TextSection>
        <ReactMarkdown>{content.about.about}</ReactMarkdown>
        <div className="align-center l-with-gutter-top-mobile">
          <div className="l-push-bottom">Want to know more?</div>
          <a className="button button-ghost-primary" href="/contact">
            Send a message
          </a>
        </div>
      </TextSection>
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
        <Link
          classname="button button-quaternary-negative-dark button-estimate-project"
          url="/estimate-project"
          text="estimate project"
        />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default ProfessionalServicesPage;
export const query = graphql`
  {
    contentfulProfessionalServicesPage {
      productPagesLeads {
        ... on ContentfulProfessionalServicesLandingPage {
          lead {
            lead
          }
          shortName
          active
          url
          icon {
            file {
              url
            }
          }
        }
      }
      qualitiesList {
        qualitiesList
      }
      about {
        about
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
    }
  }
`;
